:root {
  --font1-color: #000;
  --font2-color: #fff;
  --accent2: #e96535;
  --accent: #5eac76;
  --primary: #23282e;
  --primary-hover: #353c46;
  --error: #e84855;
  --warning: #e09600;
  --success: #71b143;
}

body {
  font-family: sans-serif;
  background-color: var(--primary);
  color: var(--font1-color);
  margin: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  height: 100vh;
  overflow-x: hidden;
}

html {
  font-size: 16px;
}

a {
  color: var(--font2-color);
  text-decoration: none;
}

.bkg-video {
  position: fixed;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: -1;
}

.card-shadow {
  -webkit-box-shadow: 0 0.175rem 0.85rem -0.02rem rgba(0, 0, 0, 0.7);
  box-shadow: 0 0.175rem 0.85rem -0.02rem rgba(0, 0, 0, 0.7);
}
.alert-container {
  position: fixed;
  text-align: center;
  left: 50%;
  width: 0;
  height: 0;
  transform: translateX(-50%);
  overflow-y: visible;
  margin-top: 1rem;
}
.alert-item {
  color: var(--font2-color);
  background-color: var(--primary);
  display: inline-block;
  padding: 0.35rem 1rem;
  border-radius: 0.75rem;
  margin: 0.45rem 0;
  transform: translateX(-50%);
  transition: 0.3s;
  -webkit-transition: 0.3s;
}
.alert-item-type-error {
  background-color: var(--error);
}
.alert-item-type-warning {
  background-color: var(--warning);
}
.alert-item-type-success {
  background-color: var(--success);
}
.alert-message {
  color: var(--font2-color);
  margin: 0;
  font-size: 1.5rem;
  white-space: nowrap;
}
.no-highlight {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.main-btn {
  font-size: 1.4rem;
  border: none;
  padding: 0.6rem 1.3rem;
  border-radius: 0.5rem;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  background-color: var(--accent);
  color: var(--font2-color);
  -webkit-box-shadow: 0 0.175rem 0.85rem -0.02rem rgba(0, 0, 0, 0.7);
  box-shadow: 0 0.175rem 0.85rem -0.02rem rgba(0, 0, 0, 0.7);
}
@media only screen and (max-width: 700px), screen and (max-height: 700px) {
  html {
    font-size: 15px;
  }
}
@media only screen and (max-width: 600px), screen and (max-height: 600px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 500px), screen and (max-height: 500px) {
  html {
    font-size: 13px;
  }
}
@media only screen and (max-width: 400px), screen and (max-height: 400px) {
  html {
    font-size: 12px;
  }
}
