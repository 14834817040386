.landing-container {
  position: relative;
  margin: 0;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.game-list-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: flex-start;
  flex-shrink: 0;
  padding: 1rem;
}
.game-container {
  position: relative;
  width: 22rem;
  height: 14rem;
  overflow: hidden;
  margin: 1.5rem;
  background-color: #333;
  border-radius: 1rem;
}
.game-container img {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

@media only screen and (max-width: 800px) {
  .game-container {
    width: 20rem;
    height: 13rem;
    margin: 1rem;
  }
}
@media only screen and (max-width: 750px) {
  .game-container {
    width: 18rem;
    height: 12rem;
  }
}
@media only screen and (max-width: 556px) {
  .game-list-container {
    justify-content: center;
    padding: 1rem 0;
  }
  .game-container {
    width: 26rem;
    height: 17rem;
    margin: 1rem;
  }
}
